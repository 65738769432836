<template>
    <PageTitle>
        <div class="flex justify-between items-center px-4">
            <div>
                <router-link to="/partners" class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800">
                    <Icons i="arrow-left" class="w-4 h-4 hover:round" />
                </router-link>
                <span class="text-gray-400 ml-3" v-if="editMode">
                    Edit Partner:
                    <span class="text-gray-500 ml-2">{{item.name}}</span>
                </span> 
                <span class="text-gray-400 ml-3" v-else>New Partner</span>

            </div>
            <div v-if="editMode">
                <Button label="Delete Partner" size="small" icon="remove" style="height:30px;" type="danger" @click="deleteItem" />
            </div>
        </div>
    </PageTitle>

    <div class="mt-10 container px-4 mx-auto mb-24 " v-if="item.id || init">

        <label class="block text-sm font-medium text-gray-700 text-xl mb-6">General Details</label>

        <div class="md:w-4/5">
            <ul class="text-gray-500 text-sm space-y-4">
                <li class="md:flex  space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Partner Name" v-model="item.name" />
                    </div>
                    <!-- <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Partner Name (French)" v-model="item.name_fr" />
                    </div> -->
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Full Number" v-model="item.number" />
                    </div>
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Address" v-model="item.address" />
                    </div>


                    <div class="flex-1">
                        <!-- <FormInput :show-errors="showErrors" label="Province" v-model="item.province" /> -->

                        <FormSelect :show-errors="showErrors" class="col-span-6 sm:col-span-3" label="Province" v-model="item.province" :options="provinceList"/>
                    </div>


                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Code" v-model="item.code" />
                    </div>
                </li>

                <li class="flex  space-x-3" v-if="false">
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Description</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.description" />
                    </div>
                    
                    

                </li>

            </ul>
        </div>
       
        <div class="mt-10">
            <Button :label="editMode ? 'Update Changes' : 'Create Partner'" type="primary" @click="submit" />   
        </div>
    </div>
</template>

<script>

import PageTitle from '@/components/PageTitle'
import {formatPrice,validateEmail,baseURL,deleteFile,attachImage} from '@/assets/helper'
import FormInput from "@/components/Forms/FormInput";
import FormSelect from "@/components/Forms/FormSelect";
import MultiImage from "@/components/Forms/MultiImage";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import regions from '@/assets/resources/regions'

export default {
    components:{
        FormInput,
        FormSelect,
        Button,
        Icons,
        PageTitle,
        MultiImage
    },
    data:( () => ({
        showErrors:false,
        partners:[],
        init:false,
        item:{
            name:'',
            // description:'',
            number:'',
            address:'',
            province:'',
        },

        provinceList: [
            {label:'None',value:''},
            {label:'Alberta',value:'Alberta'},
            {label:'British Columbia',value:'British Columbia'},
            {label:'Manitoba',value:'Manitoba'},
            {label:'New Brunswick',value:'New Brunswick'},
            {label:'Newfoundland and Labrador',value:'Newfoundland and Labrador'},
            {label:'Northwest Territories',value:'Northwest Territories'},
            {label:'Nova Scotia',value:'Nova Scotia'},
            {label:'Nunavut',value:'Nunavut'},
            {label:'Ontario',value:'Ontario'},
            {label:'Prince Edward Island',value:'Prince Edward Island'},
            {label:'Quebec',value:'Quebec'},
            {label:'Saskatchewan',value:'Saskatchewan'},
            {label:'Yukon',value:'Yukon'},
        ],

        partner:'',
        images:[],
    })),
    computed:{
        selectedCats(){
            return this.partners.filter(x => x.optIn).map(x => x.id)
        },
        editMode(){
            return Boolean(this.$route.params.id) && Boolean(this.item.id)
        },
    },
    methods:{
        async deleteConfirmed(){
            this.$store.commit('LOAD', {is:true})
            this.$api.delete(`/partners/${this.item.id}`).then( ({data}) => {
                this.$store.commit('SET_MODAL',{
                    callback:() => this.$router.push('/partners'),
                    danger:false,
                    confirm:false,
                    header:`Deleted`,
                    body:'This partner has been deleted',
                })
            }).catch( () => {
                this.$store.commit('SET_MODAL',{
                    danger:true,
                    confirm:false,
                    header:`Uh oh,`,
                    body:'An error occured while trying to delete this partner. Please try again.',
                })
            }).finally( () => {
                this.$store.commit('LOAD', {is:false})
            })
        },
        deleteItem(){
            // // console.log(this.item.name.length,'this.item.length')
            // if (this.item.products.length){
            //     this.$store.commit('SET_MODAL',{
            //         danger:true,
            //         confirm:false,
            //         okText:'Okay',
            //         header:`Cannot delete`,
            //         body:'Only partners without products can be deleted.',
            //     })
            // } else {
                this.$store.commit('SET_MODAL',{
                    callback:this.deleteConfirmed,
                    danger:true,
                    confirm:true,
                    okText:'Delete',
                    header:`This cannot be undone`,
                    body:'Are you sure you want to delete this partner?',
                })
            // }
                
        },
        validateEmail,
        isValid(){
            let valid = true
                // console.log(this.item,'this.item.this.item.')
            // if (!this.item.name.trim() || !this.item.name_fr.trim()) valid = false 
            if (!this.item.name.trim()) valid = false 

            return valid
        },
        dealWithImages(id){

            return new Promise( (resolve,reject) => {

                let promises = []
    
                for (const key in this.images) {
                    if (Object.hasOwnProperty.call(this.images, key)) {
                        const image = this.images[key];
                        
                        if(image.uploaded && !image.removed){
                            // console.log('ADDING', image.file.name)
                            promises.push(
                                attachImage({file:image.file,ref:'product',id,field:'image'})
                            )
                        } else if (!image.uploaded && image.removed){
                            // console.log('REMOVING', image.id)
                            promises.push(
                                deleteFile(image.id)
                            )
                        }
                    }
                }
                
                Promise.all(promises).then((resolved) => {
                    resolve()
                }).catch( () => {
                    reject()
                })

            })
        },
        async submit(){

            if (!this.isValid()) return this.showErrors = true

            let sendObj = JSON.parse(JSON.stringify(this.item))

            if (this.editMode){

                this.$store.commit('LOAD',{is:true})
                
                this.$api.put(`/partners/${this.item.id}`, sendObj)
                    .then( ({data}) => {
                        // console.log(data,'data put')
                        this.$store.commit('SET_MODAL',{
                            callback: () => this.$router.push(`/partners`),
                            danger:false,
                            confirm:false,
                            header:'Success',
                            body:`You have successfully updated partner: ${data.name}`
                        })
                    }).catch( () => {
                        this.$store.commit('SET_MODAL',{
                            danger:true,
                            confirm:false,
                            header:'Error',
                            body:`An error Occurred while updating. Please try again`
                        })
                    }).finally( () => {
                        this.$store.commit('LOAD',{is:false})
                    })

            } else {
                this.$store.commit('LOAD',{is:true})
                this.$api.post(`/partners`, sendObj).then( ({data}) => {
                    // console.log(data,'data post');
                    this.$store.commit('SET_MODAL',{
                        callback: () => this.$router.push(`/partners`),
                        danger:false,
                        confirm:false,
                        header:'Success',
                        body:`You have successfully created partner: ${data.name}`
                    })
                }).catch( () => {
                    this.$store.commit('SET_MODAL',{
                        danger:true,
                        confirm:false,
                        header:'Uh oh,',
                        body:`An error Occurred while updating. Please try again.`
                    })
                }).finally( () => {
                    this.$store.commit('LOAD',{is:false})
                })


            }
            
        },
        formatPrice,
    },
    async created(){

        let partners = []

        if (this.$route.params.id){

            this.$store.commit('LOAD', {is:true,msg:'Retrieving partner Information'})
            let {data} = await this.$api.get(`/partners/${this.$route.params.id}`)
            // console.log(data,'data1')
            // let {title = '',title_fr = '',name = '',name_fr = '',description = '',description_fr = '',id} = data
            let {code = '',name = '',number = '',address = '',province = '',id} = data




            // this.item = {title,title_fr,name,name_fr,description,description_fr,id}
            this.item = {code,name,number,address,province,id}

            // console.log(this.item,'this.item')

            
        } else {
            this.init = true
        }

        this.$store.commit('LOAD', {is:false,msg:''})
        

    }
}
</script>

<style>

</style>